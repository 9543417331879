<template>
	<v-card flat tile :loading="enrolledLoading" class="rounded-b-xl">
		<v-card-title>
			<h3>Manage Team</h3>
			<v-dialog v-model="dialog" max-width="950px">
				<template v-slot:activator="{ on, attrs }">
					<v-btn elevation="0" small fab v-bind="attrs" v-on="on" color="pink" class="mx-3">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</template>
				<NewEnrolled 
					:enrolled = "enrolled" 
					@NewEnrolledAdded = "getEnrolled" 
					@CloseDialog = "dialog = false"
				/>
			</v-dialog>
		</v-card-title>

		<v-card-text>
			<v-simple-table class="pa-2">
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">ID</th>
							<th class="text-left">Username</th>
							<th class="text-left">Role</th>
							<th class="text-left">Point system access</th>
							<th class="text-left">Chatbot enabled</th>
							<th class="text-left">Actions</th>
						</tr>
					</thead>
					
					<tbody v-for="user in enrolled" :key="user.id">
						<tr>
							<td>
								<span>{{ user.id }}</span>
							</td>
							<td>
								<span>{{ user.nickname }}</span>
							</td>
							<td>
								<span>{{ user.role.charAt(0).toUpperCase() + user.role.slice(1) }}</span>
							</td>
							<td>
								<span>
									<v-icon color="green lighten-1" v-if="user.point_sys_access">mdi-checkbox-marked-circle</v-icon>
									<v-icon color="red lighten-1" v-else>mdi-close-circle</v-icon>
								</span>
							</td>
							<td>
								<span>
									<v-icon color="green lighten-1" v-if="user.chatbot_enabled">mdi-checkbox-marked-circle</v-icon>
									<v-icon color="red lighten-1" v-else>mdi-close-circle</v-icon>
								</span>
							</td>
							<td>
								<Actions :user="user" @EnrolledDeleted="getEnrolled"/>
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-card-text>
	</v-card>
</template>

<script>
import Actions from './Actions.vue';
import NewEnrolled from './NewEnrolled.vue';

export default {
	components: {
		Actions,
		NewEnrolled
	},

	data() {
		return {
			dialog: false,
			loadingSyncEnrolledStreamers: false,
			enrolled: [],
			enrolledLoading: true,
			dialogDeleteEnrolled: false,
		}
	},

	async mounted() {
		await this.getEnrolled();
	},

	methods: {
		customComparator(a, b) {
			const roleOrder = ["admin", "streamer"];
			const indexA = roleOrder.indexOf(a.role);
			const indexB = roleOrder.indexOf(b.role);
			return indexA - indexB;
		},

		async getEnrolled() {
			this.enrolledLoading = true;

			try {
				const request = await fetch(`${this.$store.state.base_url}/enrolled/all`, {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});

				const res = await request.json();
				
				if (request.ok) {
					let enrolled = res.data.sort(this.customComparator);

					this.enrolled = enrolled;
				}

				if (request.status == 500 || request.status == 401) {
					if (res.error && this.$store.state.error_types.includes(res.error)) {
						this.sessionExpiredHandler(res);
						return;
					}

					throw (res);
				}
			}
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
				});
			}
			finally {
				this.enrolledLoading = false;
				this.dialog = false;
			}
		},
	},
}
</script>