<template>
    <v-card>
		<v-form class="pa-2" @submit.prevent="submitNewEnrolled" ref="form" lazy-validation>
			<v-card-title>
				<span class="text-h5 text-center">New Streamer</span>
			</v-card-title>

			<v-card-text>
				<v-container>
					<v-row>
						<v-col xl="8" lg="8" md="6" sm="12" cols="12">
							<v-autocomplete
								v-model="usernames"
								:items="twitch_users"
								:loading="isLoadingAPI"
								:search-input.sync="search"
								item-text="display_name"
								item-value="display_name"
								append-icon="mdi-twitch"
								label="Search a Twitch user"
								:rules="[v => !!v || 'At least one user is required']"
								multiple
								outlined
								required
							>
								<template v-slot:selection="data">
									<v-chip
										v-bind="data.attrs"
										:input-value="data.selected"
										close
										dense
										@click="data.select"
										@click:close="remove(data.item)"
									>
										<v-avatar left>
											<v-img :src="data.item.profile_image_url"></v-img>
										</v-avatar>
										{{ data.item.display_name }}
									</v-chip>
								</template>
								<template v-slot:item="data">
									<template>
										<v-list-item-avatar>
											<img :src="data.item.profile_image_url">
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title>{{ data.item.display_name }}</v-list-item-title>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>
						</v-col>

						<v-col xl="4" lg="4" md="6" sm="12" cols="12">
							<v-select
								flat
								outlined
								v-model="role"
								:items="roles"
								label="Role"
								:rules="[v => !!v || 'Role is required']"
								required
							></v-select>
						</v-col>

						<v-col cols="12">
							<v-checkbox 
								v-model="chatbot" 
								label="Chatbot and point system access"
								class="mx-4 my-0"
							></v-checkbox>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="$emit('CloseDialog')">Close</v-btn>
				<v-btn color="blue darken-1" text :loading="loadingBtn" :disabled="loadingBtn" type="submit">Save</v-btn>
			</v-card-actions>
		</v-form>
    </v-card>
</template>

<script>
export default {
	data() {
		return {
			loadingBtn: false,
			isLoadingAPI: false,
			usernames: null,
			role: null,
			search: null,
			roles: ['admin', 'streamer'],
			twitch_users: [],
			chatbot: false,
		}
	},

	watch: {
		search (val) {
			val && val !== this.usernames && this.fetchTwitchUser(val)
		}
	},

	methods: {
		async submitNewEnrolled () {
			try {
				if (this.$refs.form.validate()) {
					this.loadingBtn = true;
	
					const request = await fetch(`${this.$store.state.base_url}/channel/enrolled/new`, {
						method: 'POST',
						body: JSON.stringify({
							usernames: this.usernames,
							role: this.role,
							chatbot: this.chatbot
						}),
						headers: {
							"Content-type" : "application/json; charset=UTF-8",
							"Authorization" : this.$cookies.get('guardian_key_session'),
						}
					});
	
					const res = await request.json();
					
					if (request.ok) {	
						if (res.enrolledAdded) {
							this.$emit('NewEnrolledAdded');
	
							this.$notify({
								group: 'main',
								type: 'success',
								title: this.$store.state.app_name,
								text: res.msg
							});
						}
					}
					else if (request.status === 401) {
						res.forEach((msg) => {
							this.$notify({
								group: 'main',
								type: 'error',
								title: this.$store.state.app_name,
								text: msg.error
							});
						});
					}
					
					if (request.status == 500 || request.status == 401) {
						if (res.error && this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
							return;
						}

						throw (res);
					}
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
				});
			}
			finally {
				this.loadingBtn = false;
			}
		},

		async fetchTwitchUser (_username) {
			this.isLoadingAPI = true;

			setTimeout(async () => {
				try {
					const request = await fetch(`${this.$store.state.base_url}/channel/twitch/user`, {
						method: 'POST',
						body: JSON.stringify({
							username: _username
						}),
						headers: {
							"Content-type" : "application/json; charset=UTF-8",
							"Authorization" : this.$cookies.get('guardian_key_session'),
						}
					});

					const res = await request.json();
					
					if (request.ok) {	
						if (res.users.length > 0) {
							this.twitch_users.push(res.users[0]);
						}
					}
					else if (request.status === 401) {
						res.forEach((msg) => {
							this.$notify({
								group: 'main',
								type: 'error',
								title: this.$store.state.app_name,
								text: msg.error
							});
						});
					}
					
					if (request.status == 500 || request.status == 401) {
						if (res.error && this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
							return;
						}

						throw (res);
					}
				}
				catch (error) {
					this.$notify({
						group: 'main',
						type: 'error',
						title: this.$store.state.app_name,
						text: error.message === 'Failed to fetch' ? 'A network error occured. Try again later.' : error.message
					});
				}
				finally {
					this.isLoadingAPI = false;
				}
			}, 800);
		},
	},
}
</script>